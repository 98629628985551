// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-templates-remark-interview-js": () => import("./../../../src/templates/remark-interview.js" /* webpackChunkName: "component---src-templates-remark-interview-js" */),
  "component---src-templates-remark-interview-list-js": () => import("./../../../src/templates/remark-interview-list.js" /* webpackChunkName: "component---src-templates-remark-interview-list-js" */),
  "component---src-templates-remark-news-js": () => import("./../../../src/templates/remark-news.js" /* webpackChunkName: "component---src-templates-remark-news-js" */),
  "component---src-templates-remark-qa-js": () => import("./../../../src/templates/remark-qa.js" /* webpackChunkName: "component---src-templates-remark-qa-js" */),
  "component---src-templates-remark-qa-list-js": () => import("./../../../src/templates/remark-qa-list.js" /* webpackChunkName: "component---src-templates-remark-qa-list-js" */),
  "component---src-templates-remark-top-js": () => import("./../../../src/templates/remark-top.js" /* webpackChunkName: "component---src-templates-remark-top-js" */),
  "component---src-templates-school-data-page-js": () => import("./../../../src/templates/school-data-page.js" /* webpackChunkName: "component---src-templates-school-data-page-js" */),
  "component---src-templates-school-list-area-js": () => import("./../../../src/templates/school-list-area.js" /* webpackChunkName: "component---src-templates-school-list-area-js" */),
  "component---src-templates-school-list-js": () => import("./../../../src/templates/school-list.js" /* webpackChunkName: "component---src-templates-school-list-js" */),
  "component---src-templates-school-list-prefecture-js": () => import("./../../../src/templates/school-list-prefecture.js" /* webpackChunkName: "component---src-templates-school-list-prefecture-js" */)
}

